import React from "react";
import { createRoot } from "react-dom/client";
import { loadableReady } from "@loadable/component";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";

import Client from "./client/index";
import { client } from "./apollo-client";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <HelmetProvider>
          <Client />
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

loadableReady(() => {
  const rootElement: any = document.getElementById("root");
  const root = createRoot(rootElement);
  root.render(<App />);
});
