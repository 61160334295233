import { animated } from "@react-spring/web";
import styled, { createGlobalStyle } from "styled-components";

export interface Shitterface {
  url: string;
  ref: React.MutableRefObject<null>;
}

export interface IImage {
  src?: string;
  pos?: "right" | "left";
  bgSize?: string;
  style?: any;
}

interface IFooterProps {
  white?: boolean;
  absolute?: boolean;
}

interface IContentProps {
  style?: any;
}

export const GlobalStyle = createGlobalStyle`

html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  font-size: 18px;
  overscroll-behavior: none;
  @media(min-width: 800px) {
      font-size: 20px;
  }
}
*{
    box-sizing: border-box;
}

a{
  text-decoration: none;
  border-bottom: 1px solid #000;
}
a:visited{
    color: inherit;
}

strong{
    font-weight: 500;
}

h1,h2,h3{
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    margin: 0 0;
    color: #404040;
    font-weight: 400;

}
h1{
    font-size: 1.4em;
}
h2{
    font-size: 1em;
    text-transform: none;
}
h3{
  font-size: 0.9em;
  text-transform: none;  
}

div, p{
    line-height: 1.4;
}

.modal-content{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: #fff;
}

blockquote {
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-weight: 600;
}

blockquote:before {
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

`;

export const Container = styled.div`
  background-color: #fff;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

export const Content = styled.div<IContentProps>`
  grid-column: 2 / 6;
  color: #fff;
  z-index: 2;
  font-size: 1.2em;
  ${(props) => props.style}
`;

export const Head = styled.div`
  font-family: "Rajdhani", sans-serif;
  font-size: 1.2em;
  grid-column: 2;
`;

export const ImgBox2 = styled.div`
  padding: 0 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 100dvh;
  align-items: center;
  > div {
    margin: 1em auto;
    text-align: center;
    min-height: 300px;
  }
  img {
    width: 100%;
    margin: 1em 0;
    @media (min-width: 600px) {
      max-width: 30%;
    }
  }
`;

export const ImgBox = styled.div`
  padding: 0 1em;
  display: flex;
  flex-wrap: wrap;

  .column {
    max-width: 100%;
    padding: 0 1em;
    > div {
    }
    @media (min-width: 600px) {
      max-width: 50%;
    }
    @media (min-width: 900px) {
      max-width: 25%;
    }
  }

  .column img {
    margin-top: 2em;
    vertical-align: middle;
    width: 100%;
  }
`;

export const Label = styled.div`
  color: #333;
  padding: 0.5em 0 0;
  font-size: 1em;
  display: none;
`;

export const Title = styled(animated.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    color: #fff;
    font-weight: 100;
    font-size: 3em;
    text-transform: capitalize;
  }
`;

export const FooterWrap = styled.div<IFooterProps>`
    position: ${(props) => (props.absolute ? "absolute" : "relative")};
    padding: 1em 0;
    background-color: #f7f7f7;
    text-align: center;
    bottom: 0;
    color: #333;
    left: 0;
    font-size: 0.6em;
    right: 0;
    font-weight: 500;
    z-index: 1;
}
`;

export const Button = styled.a`
  background-color: #333;
  color: #fff;
  padding: 1em;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 300px;
  font-weight: 500;
  float: right;
  &:hover {
    background-color: #464646;
  }
`;

export const ContainerImage = styled.div<IImage>`
  background-image: url(${(props) => (props.src ? props.src : "none")});
  background-size: ${(props) => (props.bgSize ? props.bgSize : "auto 100%")};
  background-repeat: no-repeat;
  background-position: ${(props) => (props.pos ? props.pos : "center")};
  height: 100vh;
  grid-column: 1 / 7;
  display: flex;
  justify-content: flex-end;
  ${(props) => props.style}
`;

export const Ingress = styled.p`
  font-weight: 500;
`;

export const Background = styled.div<{ src: string; height?: number }>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  grid-column: 1 / 7;
  height: ${(props) =>
    props.height ? `${props.height}vh` : "calc(100vh - 10px)"};
  position: relative;
  border: 10px solid #fff;
  border-bottom: none;
  &:before {
    background-color: rgba(0, 0, 0, 0.35);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: all linear 0.3s;
  }
`;

const align = `
    .fright,
    .fleft {
        width: 100%;
        clear: both;
        margin: 0;
    }
    .fright {
        float: none;
    }
    .fleft {
        float: none;
    }

    .left{
        text-align:left;
    }
    .center{
        text-align:center;
    }
    .right{
        text-align:right;
    }
@media(min-width:800px) {
    .fright,
    .fleft {
        width: 300px;
        clear: both;
        margin: 0 2em;
    }
    .fright {
        float: right;
    }
    .fleft {
        float: left;
    }

}
`;

export const Article = styled.div`
  grid-column: 1 / 7;
  max-width: 800px;
  width: 100%;
  margin: 2em auto;

  ${align}

  img {
    width: 100%;
    margin: 2em auto;
  }

  h1,
  h2,
  h3,
  p {
    margin-left: 2rem;
    margin-right: 2rem;
    font-weight: 400;
    text-transform: none;
  }

  [contenteditable="true"] {
    border: 1px solid #eee;
    margin-left: 2em;
    margin-right: 2em;
    padding: 10px;
  }
  select,
  button {
    margin: 1em 2em;
    font-size: 1em;
  }
`;

export const Section = styled.section`
  grid-column: 2 / 6;
  padding: 4em 0 6em;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 1px;
  padding: 5px 5px;
  width: 100%;
  &:after {
    content: "";
    flex: auto;
  }
`;

export const ImgWrap = styled.div<any>`
  position: relative;
  height: 50vh;
  min-width: 300px;
  margin: 5px 5px 5px;
  flex-grow: 1;
  flex-shrink: 1;
  @media (max-aspect-ratio: 1/1) {
    height: 30vh;
  }
  @media (max-height: 480px) {
    height: 80vh;
  }
  img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`;

export const SectionTitle = styled.div`
  font-family: "Oswald", sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
  padding: 0px 0 0.3em;
  width: 200px;
  font-weight: 400;
`;

export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &.right {
    justify-self: flex-end;
  }
  @media (min-width: 800px) {
    margin: 0em 2em;
    width: calc(50% - 4em);
    max-width: 500px;
  }
`;

export const ColumnContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`;
