import React from "react";
import loadable from "@loadable/component";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { useTransition, animated, config } from "@react-spring/web";
import { Location } from "history";
import { GlobalStyle } from "./shared/styled";

const Frontpage = loadable(() => import("./frontpage"));
const About = loadable(() => import("./about"));
const Galleries = loadable(() => import("./galleries"));
const Gallery = loadable(() => import("./gallery"));
const Blog = loadable(() => import("./blog/blog"));
const Post = loadable(() => import("./blog/post"));

const NotFound = loadable(() => import("./notfound"));

interface ClientComponentProps extends RouteComponentProps {
  location: Location;
}

const routes = (loc: any) => (
  <Switch location={loc}>
    <Route exact path="/" component={Frontpage} />
    <Route exact path="/om-meg" component={About} />
    <Route exact path="/foto" component={Galleries} />
    <Route exact path="/foto/:name" component={Gallery} />
    <Route exact path="/blogg" component={Blog} />
    <Route
      exact
      path="/blogg/:slug"
      render={(props) => (
        <Post context={props.staticContext} match={props.match} />
      )}
    />
    <Route render={(props) => <NotFound context={props.staticContext} />} />
  </Switch>
);

const Client: React.FC<ClientComponentProps> = ({ location, history }) => {
  React.useEffect(
    () =>
      history.listen((location, action) => {
        // do something on route change
        // for my example, close a drawer
        window.scrollTo(0, 0);
      }),
    []
  );

  const transitions = useTransition(location, {
    config: config.slow,
    from: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <>
      <GlobalStyle />
      {typeof window !== "undefined" ? (
        transitions((props: any, item: any) => (
          <animated.div key={item.key} style={props}>
            {routes(item)}
          </animated.div>
        ))
      ) : (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            opacity: 0,
          }}
        >
          {routes(location)}
        </div>
      )}
    </>
  );
};

export default withRouter(Client);
