import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import fetch from "isomorphic-unfetch";

declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}

export const client = new ApolloClient({
  ssrMode: false,
  link: new BatchHttpLink({
    uri: process.env.GRAPHPATH,
    fetch,
    credentials: "same-origin",
  }),
  cache: new InMemoryCache().restore(
    typeof window !== "undefined" ? window.__APOLLO_STATE__ : ""
  ),
});

export const serverclient = (req: any) => {
  let errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    ssrMode: true,
    // Remember that this is the interface the SSR server will use to connect to the
    // API server, so we need to ensure it isn't firewalled, etc
    link: ApolloLink.from([
      errorLink,
      createHttpLink({
        uri: process.env.GRAPHPATH,
        fetch,
        credentials: "same-origin",
        headers: {
          cookie: req.header("Cookie"),
        },
      }),
    ]),
    cache: new InMemoryCache(),
  });
};
